import {
  LOGIN_REQUEST,
  SUPERVISOR_LOGIN_REQUEST,
  SUPERVISOR_NORMAL_LOGIN_REQUEST,
  POST_LOGIN_REQUEST,
  SUPERVISOR_LOGOUT,
  RESET_ERROR,
  REMOVE_TOKEN,
  SET_ME_REQUEST,
  GET_ME_REQUEST,
  STAFF_VERIFY_REQUEST,
  CHECK_IN_REQUEST,
  CHECK_OUT_REQUEST,
  SITE_INDUCTION_REQUEST,
  GET_COVID_QUESTION_REQUEST,
  SET_COVID_QUESTION_REQUEST,
  GET_SITE_HAZARDS,
  RESET_SITE_HAZARDS,
  TINCAN_LOGIN_REQUEST
} from "./constants";

export const loginRequest = (payload) => {
  return {
    type: LOGIN_REQUEST,
    ...payload,
  };
};
export const supervisorLoginRequest = (payload) => {
  return {
    type: SUPERVISOR_LOGIN_REQUEST,
    ...payload,
  };
};

export const supervisorEmailPasswordLoginRequest = (payload) => {
  return {
    type: SUPERVISOR_NORMAL_LOGIN_REQUEST,
    ...payload,
  };
};

export const supervisorLogout = (payload) => {
  return {
    type: SUPERVISOR_LOGOUT,
    ...payload,
  };
};

export const postLoginRequest = (payload) => {
  return {
    type: POST_LOGIN_REQUEST,
    payload,
  };
};
export const removeToken = (payload) => {
  return {
    type: REMOVE_TOKEN,
    payload,
  };
};

export const resetError = (payload) => {
  return {
    type: RESET_ERROR,
    payload,
  };
};

export const getMeRequest = (payload) => {
  return {
    type: GET_ME_REQUEST,
    payload,
  };
};

export const setMeRequest = (payload) => {
  return {
    type: SET_ME_REQUEST,
    payload,
  };
};

export const staffVerifyRequest = (payload) => {
  return {
    type: STAFF_VERIFY_REQUEST,
    payload,
  };
};

export const checkInRequest = (payload) => {
  return {
    type: CHECK_IN_REQUEST,
    payload,
  };
};

export const checkOutRequest = (payload) => {
  return {
    type: CHECK_OUT_REQUEST,
    payload,
  };
};

export const siteInductionRequest = (payload) => {
  return {
    type: SITE_INDUCTION_REQUEST,
    payload,
  };
};

export const getCovidQuestionRequest = (payload) => {
  return {
    type: GET_COVID_QUESTION_REQUEST,
    payload,
  };
};

export const seCovidQuestionsRequest = (payload) => {
  return {
    type: SET_COVID_QUESTION_REQUEST,
    payload,
  };
};

export const getSiteHazardsRequest = (payload) => {
  return {
    type: GET_SITE_HAZARDS,
    payload,
  };
};
export const resetSiteHazardsRequest = (payload) => {
  return {
    type: RESET_SITE_HAZARDS,
    payload,
  };
};

export const tincanLoginRequest = (payload) => {
  return {
    type: TINCAN_LOGIN_REQUEST,
    payload,
  };
};
