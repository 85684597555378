import React from "react";
import classes from "./footer.module.scss";

import cn from "classnames";
import PlusIcon from "assets/supervisorIcons/plusIcon.js";
import Icon from "components/UI/IconComponent";

const Footer = ({
  loading,
  selectedHazard,
  globalChangeCount,
  onDelete,
  onSaveChanges,
  onNewHazard,
  onCancelSelectedHazard,
  onCancelChanges,
}) => {
  return (
    <div className={classes.buttonContainer}>
      <div className={classes.buttonWrapper}>
        {selectedHazard ? (
          <div className={classes.selectedButtonWrapper}>
            <button
              disabled={loading}
              className={classes.deleteButton}
              onClick={() => {
                onDelete();
              }}
            >
              <div className={classes.buttonContent}>Delete</div>
              <Icon
                icon="delete"
                color="#ffffff"
                size="14px"
                className={classes.buttonIcon}
              />
            </button>
            <div className={classes.rightButtonWrapper}>
              <button
                id="1"
                disabled={loading}
                className={classes.lightButton}
                onClick={() => {
                  onCancelSelectedHazard();
                }}
              >
                <div className={classes.buttonContent}>Cancel</div>
                <Icon
                  icon="close"
                  color="#ffffff"
                  size="14px"
                  className={classes.buttonIcon}
                />
              </button>
              <button
                disabled={loading}
                className={cn(classes.darkButton, classes.w124)}
                onClick={() => {
                  onSaveChanges();
                }}
              >
                <div className={classes.buttonContent}>Save</div>
                <Icon
                  icon="tick"
                  color="#ffffff"
                  size="14px"
                  className={classes.buttonIcon}
                />
              </button>
            </div>
          </div>
        ) : globalChangeCount > 0 ? (
          <div className={cn(classes.buttonContent, classes.mr100)}>
            <button
              id="2"
              type="btn"
              disabled={loading}
              className={classes.lightButton}
              onClick={() => {
                onCancelChanges();
              }}
            >
              <div className={classes.buttonContent}>Cancel</div>
              <Icon
                icon="close"
                color="#ffffff"
                size="14px"
                className={classes.buttonIcon}
              />
            </button>
            <button
              disabled={loading}
              className={classes.darkButton}
              onClick={() => {
                onSaveChanges();
              }}
            >
              <div className={classes.buttonContent}>Save Changes</div>
              <Icon
                icon="tick"
                color="#ffffff"
                size="14px"
                className={classes.buttonIcon}
              />
            </button>
          </div>
        ) : (
          <button
            disabled={loading}
            className={cn(classes.darkButton, classes.mr100)}
            onClick={() => {
              onNewHazard();
            }}
          >
            <div className={cn(classes.buttonContent)}>
              New Content
              <div className={classes.icon}>
                <PlusIcon />
              </div>
            </div>
          </button>
        )}
      </div>
    </div>
  );
};

export default Footer;
