import React, { useState, useEffect } from "react";
import classes from "./tincan.module.scss";
import QRCode from "react-qr-code";
import queryString from "query-string";

const TinCan = () => {
  
  const [qrData, setQrData] = useState(`${window.location.origin}/tincan`);
  
  const parsed = queryString.parse(window.location.search);

  const actor = JSON.parse(parsed?.actor);

  useEffect(() => {
    
     if(actor)
       setQrData(`${window.location.origin}/tincan-login?actor=`+parsed?.actor + '&auth=' + parsed?.auth
                    + '&activity_id=' + parsed?.activity_id
                );

  }, []);

  console.log('parsed',actor);

  return (
    <div className={classes.Container}>
          
         <h3>Please scan below QR code on your mobile device to start your induction</h3> 

         <QRCode id="QRCode" value={qrData} size={250} />
    </div>
  );
};

export default TinCan;
