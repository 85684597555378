import React, { useState } from "react";
import classes from "./cardBox.module.scss";
import IconComponent from "components/UI/IconComponent";
import DragIcon from "../../icons/drag.svg";
import ActionIcon from "../../icons/small.svg";
import Switch from "../switch";
import cn from "classnames";
import { isNil, isEmpty, size } from "lodash";
import { ClickOutside } from "utils/helper";

const CardBox = ({
  selectedHazard,
  setSelectedHazard,
  item,
  totalSites,
  setSelectActionIndex,
  index,
  selectedActionIndex,
  hazardList,
  setHazardList,
  setGlobalChangeCount,
  deleteHazard,
  setEditBoxOpen,
}) => {
  const isSelected = selectedHazard?.dragId === item?.dragId;
  const [isSwitchFocused, setSwithFocus] = useState(false);
  const [isActionFocused, setActionFocused] = useState(false);

  const selectHazard = () => {
    if (!isSwitchFocused && !isActionFocused && selectedActionIndex !== index) {
      setSelectedHazard(item);
      setEditBoxOpen(true);
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  };

  const actionList = [
    {
      icon: "edit",
      text: "Edit",
      action: (item) => {
        setSelectedHazard(item);
        setSelectActionIndex(false);
        setEditBoxOpen(true);
      },
      color: "#6FCF97",
      textColor: "#28353C",
    },
    {
      icon: "duplicate",
      text: "Duplicate",
      action: () => {},
      color: "#6FCF97",
      textColor: "#28353C",
      action: (item) => {
        let newHazardList = [...hazardList];
        newHazardList.splice(index + 1, 0, {
          ...item,
          name: "DUPLICATED " + item.name,
          id: null,
          dragId: `${item.dragId}+${Math.floor(Math.random() * 1231241) + 1}`,
        });
        setHazardList(newHazardList);
        setSelectActionIndex(false);
        setGlobalChangeCount((val) => val + 1);
        setEditBoxOpen(true);
      },
    },
    // {
    //   icon: "remove",
    //   text: "Remove",
    //   action: () => {},
    //   color: "#6FCF97",
    //   textColor: "#28353C",
    // },
    {
      icon: "delete",
      text: "Delete",
      action: (item) => {
        if (item.id) {
          deleteHazard({ id: item.id });
          setSelectActionIndex(false);
          setSelectedHazard(false);
        } else {
          let newHazardList = [...hazardList];
          newHazardList.splice(index, 1);
          setHazardList(newHazardList);

          setSelectActionIndex(false);
          setSelectedHazard(false);
        }
      },
      color: "#e26c82",
      textColor: "#e26c82",
    },
  ];

  return (
    <div
      className={cn(
        classes.cardBox,
        isSelected && classes.z100,
        isSelected && item.level === 0 && classes.borderYellow,
        isSelected && item.level === 1 && classes.borderRed,
        item.level === 0 && isSelected === false && classes.borderYellowIdle,
        item.level === 1 && isSelected === false && classes.borderRedIdle
      )}
      onClick={() => selectHazard()}
    >
      <div className={classes.wrapper}>
        <div className={classes.dragBox}>
          <img src={DragIcon} className={classes.DragIcon} />
        </div>
        <div className={classes.mainBox}>
          {item.image_url && item.image_url.indexOf(".pdf") == -1 && (
            <img src={item.image_url} className={classes.mainImage} />
          )}
          <div>
            {!isNil(item.icon) && !isEmpty(item.icon) && (
              <div className={classes.iconBox}>
                <div
                  className={classes.cardIconWrapper}
                  style={{
                    backgroundColor: item.level === 1 ? "#e26c82" : "#f2c75e",
                  }}
                >
                  <IconComponent
                    size="70%"
                    icon={item.icon}
                    color={"#ffffff"}
                  />
                </div>
              </div>
            )}
            <div>
              <span
                style={{ color: item.level === 1 ? "#e26c82" : "#f2c75e" }}
                className={classes.cardTitle}
              >
                {item.name}
              </span>
              <span className={classes.cardMessage}>{item.message}</span>

              <div
                className={cn(
                  classes.informationWrapper,
                  isSelected && classes.isSelectedInformation
                )}
              >
                <div className={classes.siteCountWrapper}>
                  <span className={classes.siteCountTitle}>Sites:</span>
                  <div className={classes.siteCount}>
                    <span className={classes.siteCountLength}>
                      {item.is_for_all_sites === 1
                        ? totalSites
                        : size(item.site_list)}
                    </span>
                  </div>
                </div>
                <Switch
                  onMouseEnter={() => setSwithFocus(true)}
                  onMouseLeave={() => setSwithFocus(false)}
                  onChange={(val) => {
                    setSelectedHazard({
                      ...item,
                      is_active: item.is_active === 1 ? 0 : 1,
                    });
                    setGlobalChangeCount((val) => val + 1);
                  }}
                  checked={item.is_active === 1 ? true : false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.actionBox}>
          <div
            className={classes.actionButton}
            onMouseEnter={() => setActionFocused(true)}
            onMouseLeave={() => setActionFocused(false)}
            onClick={() => {
              setSelectActionIndex(index);
            }}
          >
            <IconComponent icon="action" size={25} color="#6FCF97" />
          </div>
          {selectedActionIndex === index && (
            <ClickOutside
              onClick={() => {
                if (selectedActionIndex === index) {
                  setSelectActionIndex(false);
                }
              }}
            >
              <div id="popover" className={classes.popOver} onClick={() => {}}>
                {actionList.map((listItem) => (
                  <div
                    className={classes.popOverItem}
                    onClick={() => listItem.action(item, index)}
                  >
                    <IconComponent
                      icon={listItem.icon}
                      size={20}
                      color={listItem.color}
                    />
                    <span style={{ color: listItem.textColor }}>
                      {listItem.text}
                    </span>
                  </div>
                ))}
              </div>
            </ClickOutside>
          )}
        </div>
      </div>
    </div>
  );
};
export default CardBox;
