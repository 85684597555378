import React, { useEffect, useState } from "react";
import classes from "./inductions.module.scss";
import { getInductionRequest } from "store/induction/actions";
import { updateInductionReq,duplicateInductionReq } from "store/supervisor/actions";
import {
  makeSelectInduction,
  makeSelectLoading
} from "store/induction/selector";

import {
  makeSelectInductionDetail
} from "store/supervisor/selector";

import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import Table from "components/Supervisor/table";
import Button from "components/Supervisor/button";
import DeleteModal from "components/Supervisor/modal";

import DeleteIcon from "assets/supervisorIcons/deleteIcon.js";
import PlusIcon from "assets/supervisorIcons/plusIcon.js";
import Pagination from "components/Supervisor/pagination";

const InductionList = ({ 
  inductions, 
  getInductionList,
  updateInduction,
  duplicateInduction, 
  inductionDetail,
  loading, 
  history }) => {

  const [deleteModal, setDeleteModal] = useState({
    open: false,
    type: null,
    action: null,
    cancel: null,
  });

  useEffect(() => {
    getInductionList();
  }, []);

  useEffect(() => {
    getInductionList();
  }, [inductionDetail]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      setSelectedPage(1);
      getInductionList({ search: event.target.value });
    }
  };

  const rowActions = [
    {    
      name: "Edit",
      action: (val) => {
        history.push(`/supervisor-induction-editor?id=${val.id}`);
      },
    },
    {    
      name: "Duplicate",
      action: (val) => {
         duplicateInduction({'id': val?.id})
      },
    },
    /*
    {    
      name: "Unpublish",
      action: (val) => {
        updateInduction({'id': val?.id, 'status':0})
      },
    },
    */
    

    // {
    //   name: "Delete",
    //   action: (val) => {
    //     setDeleteModal({
    //       open: true,
    //       type: "delete",
    //       action: () => {
    //         deleteCompanyReq({
    //           id: val.id,
    //           callback: async () => {
    //             await delay(1000);
    //             getinductions();
    //           },
    //         });
    //         setDeleteModal({
    //           open: false,
    //           type: null,
    //           action: null,
    //           cancel: null,
    //         });
    //       },
    //       cancel: () =>
    //         setDeleteModal({
    //           open: false,
    //           type: null,
    //           action: null,
    //           cancel: null,
    //         }),
    //     });
    //   },
    // },
  ];

  const inductionsTableData = (item) => {
    const data = item.map((a) => {
      return {
        id: a.id,
        values: [
          {
            value: a.image,
            id: a.id,
            raw: item,
            component: <img src={a.image} className={classes.image} />,
          },
          {
            value: a.name,
            raw: item,
          },
          {
            value: a.description,
          },
          {
            value: a.status == 1 ? 'Published' : 'Draft',
            component: (
              <div className={classes.statusWrapper}>
                {a.status == 1 ? (
                  <div className={classes.statusPublished}>{"Published"}</div>
                ) : (
                  <div className={classes.statusDraft}>{"Draft"}</div>
                )}
              </div>
            ),
          },
        ],
      };
    });

    const labels = [
      { label: "Image" },
      { label: "Name" },
      { label: "Description" },
      { label: "Status" },
    ];

    return {
      columns: data,
      labels: labels,
    };
  };
  const [PAGE_COUNT, setPageCount] = useState(null);

  useEffect(() => {
    if (inductions) {
      setInductionList(inductionsTableData(inductions.results));
      setPageCount(inductions.items_per_page);

      const newRows = inductions.results.map((item, i) => {
        return {
          id: item.id,
          checked: false,
        };
      });
      setSelectedRows(newRows);
    }
  }, [inductions]);

  const [selectedPage, setSelectedPage] = useState(1);

  const [searchState, setSearchState] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [inductionList, setInductionList] = useState([]);

  const onSearch = (e) => {
    setSearchState(e.target.value);
    if (e.target.value.length === 0) {
      getInductionList({ search: e.target.value });
    }
  };

  return (
    <>
      <div className={classes.inductionsContainer}>
        <h1 className={classes.titleHead}>Induction List</h1>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: "20px",
          }}
        >
          {selectedRows &&
            selectedRows.filter((item) => item.checked).length > 0 && (
              <Button
                onClick={() => {
                  //   setDeleteModal({
                  //     open: true,
                  //     type: "delete",
                  //     action: () => {
                  //       selectedRows
                  //         .filter((item) => item.checked)
                  //         .forEach((item, index) => {
                  //           deleteCompanyReq({
                  //             id: item.id,
                  //             end:
                  //               index + 1 === selectedRows.length
                  //                 ? null
                  //                 : {
                  //                     callback: () => getinductions(),
                  //                   },
                  //           });
                  //         });
                  //       setDeleteModal({
                  //         open: false,
                  //         type: null,
                  //         action: null,
                  //         cancel: null,
                  //       });
                  //     },
                  //     cancel: () =>
                  //       setDeleteModal({
                  //         open: false,
                  //         type: null,
                  //         action: null,
                  //         cancel: null,
                  //       }),
                  //   });
                }}
                icon={() => {
                  return <DeleteIcon />;
                }}
                text={"Delete"}
                color={"#E26C82"}
              />
            )}

          <Button
            width={"200px"}
            onClick={() => console.log("new")}
            icon={() => {
              return <PlusIcon />;
            }}
            text={"New Induction"}
          />
        </div>
        <div className={classes.inductionsContainer}>
          <Table
            width={"100%"}
            handleKeyDown={handleKeyDown}
            isSearchable={true}
            searchState={searchState}
            loading={loading}
            onSearch={(e) => onSearch(e)}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            data={inductionList}
            rowActions={rowActions}
            clickable={true}
          />
        </div>
        <>
          {PAGE_COUNT && inductions && inductions.count > PAGE_COUNT && (
            <Pagination
              totalCount={inductions?.count}
              pageCount={PAGE_COUNT}
              currentPage={selectedPage}
              onChange={(val) => {
                getInductionList({ page: val + 1 });
                setSelectedPage(val + 1);
              }}
            />
          )}
        </>

        <DeleteModal
          open={deleteModal.open}
          cancel={deleteModal.cancel}
          action={deleteModal.action}
          type={deleteModal.type}
          loading={loading}
          deleteTitle={"You are about to delete a induction."}
        />
      </div>
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  inductions: makeSelectInduction(),
  loading: makeSelectLoading(),
  inductionDetail: makeSelectInductionDetail(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getInductionList: (val) => dispatch(getInductionRequest(val)),
    updateInduction: (val) => dispatch(updateInductionReq(val)),
    duplicateInduction: (val) => dispatch(duplicateInductionReq(val)),
    
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InductionList);
