import { createSelector } from "reselect";
import { initialState } from "./reducer";

const selectSupervisor = (state) => state.supervisor || initialState;

const makeSelectContractorList = () =>
  createSelector(selectSupervisor, (state) => state.contractorList);

const makeSelectContractorStats = () =>
  createSelector(selectSupervisor, (state) => state.contractorStats);

const makeSelectDashboard = () =>
  createSelector(selectSupervisor, (state) => state.dashboard);

const makeSelectLoading = () =>
  createSelector(selectSupervisor, (state) => state.loading);

const makeSelectError = () =>
  createSelector(selectSupervisor, (state) => state.error);

const makeSelectRoles = () =>
  createSelector(selectSupervisor, (state) => state.roles);

const makeSelectPermissions = () =>
  createSelector(selectSupervisor, (state) => state.permissions);

const makeSelectUsers = () =>
  createSelector(selectSupervisor, (state) => state.users);

const makeSelectCurrentUser = () =>
  createSelector(selectSupervisor, (state) => state.currentUser);

const makeSelectCurrentRole = () =>
  createSelector(selectSupervisor, (state) => state.currentRole);

const makeSelectCompanies = () =>
  createSelector(selectSupervisor, (state) => state.companies);

const makeSelectSites = () =>
  createSelector(selectSupervisor, (state) => state.sites);

const makeSelectCurrentSite = () =>
  createSelector(selectSupervisor, (state) => state.currentSite);

const makeSelectSiteStats = () =>
  createSelector(selectSupervisor, (state) => state.siteStats);

const makeSelectSiteContractors = () =>
  createSelector(selectSupervisor, (state) => state.contractors);

const makeSelectReports = () =>
  createSelector(selectSupervisor, (state) => state.reports);

const makeSelectAllReports = () =>
  createSelector(selectSupervisor, (state) => state.allReports);

const makeSelectCardReports = () =>
  createSelector(selectSupervisor, (state) => state.cardReports);

const makeSelectAllCardsReports = () =>
  createSelector(selectSupervisor, (state) => state.allCardReports);

const makeSelectAttendanceReports = () =>
  createSelector(selectSupervisor, (state) => state.attendanceReports);

const makeSelectAllAttendancesReports = () =>
  createSelector(selectSupervisor, (state) => state.allAttendanceReports);

const makeSelectAllReportsLoading = () =>
  createSelector(selectSupervisor, (state) => state.aloading);

const makeSelectWeeklyActivity = () =>
  createSelector(selectSupervisor, (state) => state.weeklyActivity);

const makeSelectWeeklyLoading = () =>
  createSelector(selectSupervisor, (state) => state.weeklyLoading);

const makeSelectAllContractors = () =>
  createSelector(selectSupervisor, (state) => state.allContractors);

const makeSelectPermissionError = () =>
  createSelector(selectSupervisor, (state) => state.permissionError);

const makeSelectVisitorList = () =>
  createSelector(selectSupervisor, (state) => state.visitorList);

const makeSelectStaffList = () =>
  createSelector(selectSupervisor, (state) => state.staffList);

const makeSelectContractorCards = () =>
  createSelector(selectSupervisor, (state) => state.cards);

const makeSelectAttendanceDetail = () =>
  createSelector(selectSupervisor, (state) => state.attendanceDetail);

const makeSelectRegions = () =>
  createSelector(selectSupervisor, (state) => state.regions);

const makeSelectDateFilter = () =>
  createSelector(selectSupervisor, (state) => state.reportingDateFilter);

const makeSelectFilterLoading = () =>
  createSelector(selectSupervisor, (state) => state.filterLoading);

const makeSelectEmergencyNotifications = () =>
  createSelector(selectSupervisor, (state) => state.notifications);

const makeSelectNotificationsTemplates = () =>
  createSelector(selectSupervisor, (state) => state.notificationTemplates);

const makeSelectHazards = () =>
  createSelector(selectSupervisor, (state) => state.hazards);

const makeSelectHazardImage = () =>
  createSelector(selectSupervisor, (state) => state.hazardImage);

const makeSelectHazardImageSuccess = () =>
  createSelector(selectSupervisor, (state) => state.hazardImageSuccess);

const makeSelectHazardImageLoading = () =>
  createSelector(selectSupervisor, (state) => state.hazardImageLoading);

const makeSelectHazardImageError = () =>
  createSelector(selectSupervisor, (state) => state.hazardImageError);

const makeSelectHazardSettings = () =>
  createSelector(selectSupervisor, (state) => state.hazardSettings);

const makeSelectPeople = () =>
  createSelector(selectSupervisor, (state) => state.people);

const makeSelectInductionDetail = () =>
  createSelector(selectSupervisor, (state) => state.induction);  

const makeSelectInductionImage = () =>
  createSelector(selectSupervisor, (state) => state.inductionImage);

const makeSelectInductionImageSuccess = () =>
  createSelector(selectSupervisor, (state) => state.inductionImageSuccess);

const makeSelectInductionImageLoading = () =>
  createSelector(selectSupervisor, (state) => state.inductionImageLoading);

const makeSelectInductionImageError = () =>
  createSelector(selectSupervisor, (state) => state.inductionImageError);

export {
  selectSupervisor,
  makeSelectLoading,
  makeSelectError,
  makeSelectContractorList,
  makeSelectContractorStats,
  makeSelectDashboard,
  makeSelectRoles,
  makeSelectPermissions,
  makeSelectUsers,
  makeSelectCurrentRole,
  makeSelectCurrentUser,
  makeSelectCompanies,
  makeSelectSites,
  makeSelectCurrentSite,
  makeSelectSiteStats,
  makeSelectSiteContractors,
  makeSelectReports,
  makeSelectAllReports,
  makeSelectCardReports,
  makeSelectAllCardsReports,
  makeSelectAttendanceReports,
  makeSelectAllAttendancesReports,
  makeSelectAllReportsLoading,
  makeSelectWeeklyActivity,
  makeSelectWeeklyLoading,
  makeSelectAllContractors,
  makeSelectPermissionError,
  makeSelectContractorCards,
  makeSelectAttendanceDetail,
  makeSelectRegions,
  makeSelectVisitorList,
  makeSelectStaffList,
  makeSelectDateFilter,
  makeSelectFilterLoading,
  makeSelectEmergencyNotifications,
  makeSelectNotificationsTemplates,
  makeSelectHazards,
  makeSelectHazardImage,
  makeSelectHazardSettings,
  makeSelectPeople,
  makeSelectHazardImageError,
  makeSelectHazardImageLoading,
  makeSelectHazardImageSuccess,
  makeSelectInductionDetail,
  makeSelectInductionImage,
  makeSelectInductionImageError,
  makeSelectInductionImageLoading,
  makeSelectInductionImageSuccess
};
