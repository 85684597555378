import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

//styles
import classes from "./hazards.module.scss";

//actions starts
import {
  getHazardsReq,
  postHazardsReq,
  getSitesRequest,
  resetHazardImageReq,
  deleteHazardReq,
  resetHazardsReq,
} from "store/supervisor/actions";
//actions ends

import {
  makeSelectHazards,
  makeSelectSites,
  makeSelectLoading,
} from "store/supervisor/selector";

//modules starts
import EditArea from "./editArea";
//modules end

//components starts
import Input from "components/Supervisor/input";
import Menu from "components/Supervisor/menu";
import ExistingHazardsSelector from "./components/existingHazardsSelector/index";
import CardBox from "./components/cardBox";
import Loading from "components/UI/loading";
import RouteLeavingGuard from "components/UI/routeGuard";
import Footer from "./components/footer";
import ReactLoading from "react-loading";

//components ends

//utils start
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { isNil, isEmpty, omit, size } from "lodash";
import cn from "classnames";
import { getItemStyle, getListStyle, reorder } from "./utils";
import { randomIntFromInterval, delay } from "utils/helper";

import { pages } from "./lib";
//utils ends

const Hazards = ({
  sites,
  getSites,
  hazards,
  postHazards,
  getHazards,
  loading,
  resetHazardImage,
  deleteHazard,
  history,
  resetHazards,
}) => {
  const [siteOptions, setSiteOptions] = useState([]);
  const [selectedSite, setSelectedSite] = useState();
  const [hazardList, setHazardList] = useState([]);

  const totalSites = size(siteOptions);

  const [globalChangeCount, setGlobalChangeCount] = useState(0);

  const [selectedHazard, setSelectedHazard] = useState(false);
  const [selectedActionIndex, setSelectActionIndex] = useState(null);
  const [isEditBoxOpen, setEditBoxIsOpen] = useState(null);

  const [isAnimationActive, setAnimationActive] = useState(false);
  const [isExistingAnimationActive, setExistingAnimationActive] =
    useState(false);

  const setEditBoxOpen = async (val) => {
    if (val === true) {
      setEditBoxIsOpen(true);
      setAnimationActive(true);
      //await delay(1000);
      setTimeout(function () {
        setAnimationActive(false);

      }, 1000);
      //setAnimationActive(false);
    } else if (val === false) {
      setEditBoxIsOpen(false);
      setExistingAnimationActive(true);
      await delay(1000);
      setExistingAnimationActive(false);
    }
  };

  useEffect(() => {
    resetHazards();
    getSites();
    resetHazardImage();
  }, []);

  useMemo(() => {
    if (selectedHazard) {
      let list = [...hazardList];
      list = hazardList.map((item) => {
        if (item?.dragId === selectedHazard?.dragId) {
          return selectedHazard;
        } else {
          return item;
        }
      });

      setHazardList(list);
      resetHazardImage();
      
      setTimeout(function () {
        //scroll to element
        var element = document.getElementById('droppableList').querySelectorAll('[data-rbd-draggable-id="'+selectedHazard.dragId +'"]')[0];
        console.log('found element',element);

        if(element)
           element.scrollIntoView({ block: 'center',  behavior: 'smooth' });
      },1000);

    }
  }, [selectedHazard]);

  useEffect(() => {
    if (!isNil(hazards)) {
      const initHazards = hazards?.results?.map((item) => {
        return {
          dragId: item.id,
          ...item,
          site_list: !isEmpty(item.site_list)
            ? item.site_list.map((item) => {
                return {
                  value: item.id,
                  label: item.name,
                };
              })
            : item.site_list,
          is_active: item.is_active === true ? 1 : 0,
          is_for_all_sites: item.is_for_all_sites === true ? 1 : 0,
        };
      });
      setHazardList(initHazards);
    }
  }, [hazards]);

  useEffect(() => {
    if (sites) {
      const opts = sites?.results?.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
      setSiteOptions(opts);
    }
  }, [sites]);

  useEffect(() => {
    if (selectedSite) {
      getHazards({ siteId: selectedSite });
    }
  }, [selectedSite]);

  const onDragEnd = (result) => {
    // dropped outsdragIde the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      hazardList,
      result.source.index,
      result.destination.index
    );

    setHazardList(items);
    setGlobalChangeCount((val) => val + 1);
  };

  // a little function to help us with reordering the result

  const addNewItem = (item) => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    const newItem = {
      ...item,
      dragId: hazardList.length + item?.name?.length + item.dragId,
    };
    let newList = [newItem, ...hazardList];
    setHazardList(newList);
    setSelectedHazard(newItem);
    setEditBoxOpen(true);
  };

  const saveChanges = () => {
    let newList = [...hazardList];

    var orderIndex = 0;
    newList = newList.map((item, index) => {
      let newItem = { ...item };

      if (!isEmpty(newItem?.site_list)) {
        newItem["site_list"] = newItem?.site_list?.map(
          (newItem) => newItem.value
        );
      }
      if (newItem?.is_for_all_sites === 1) {
        omit(newItem, "is_for_all_sites");
      }
      newItem = omit(newItem, "dragId");
      newItem = omit(newItem, "template_id");
      newItem = omit(newItem, "image_url");
      newItem = omit(newItem, "created");

      if (newItem?.end_date) {
        newItem["end_date"] = new Date(newItem.end_date).getTime() / 1000;
      } else {
        delete newItem.end_date;
      }

      if (newItem?.start_date) {
        newItem["start_date"] = new Date(newItem.start_date).getTime() / 1000;
      } else {
        delete newItem.start_date;
      }

      if (isNil(newItem.image_id) || isEmpty(newItem.image_id)) {
        item = omit(item, "image_id");
      }

      if (isNil(newItem.id) || isEmpty(newItem.id)) {
        item = omit(item, "id");
      }

      orderIndex += 1;
      newItem.order = orderIndex;

      return {
        type: !isNil(newItem?.image_id) ? 2 : !isNil(newItem?.icon) ? 1 : 0,
        ...newItem,
      };
    });

    postHazards({
      hazards: JSON.stringify(newList),
      callBack: () => {
        getHazards();
        setSelectedHazard(false);
        setEditBoxOpen(false);
        setSelectActionIndex(false);
        setGlobalChangeCount(0);
      },
    });
  };

  if (loading) {
    return (
      <div className={classes.loadingContainer}>
        {" "}
        <ReactLoading
          type={"spin"}
          color={"#20a8d8"}
          height={300}
          width={300}
        />
      </div>
    );
  }

  return (
    <>
      <div
        className={cn(
          classes.hazardsContainer,
          "no-right-padding"
          // selectedHazard && "requires-no-scroll"
        )}
      >
        {/* title & menu section */}
        <div className={classes.headingContainer}>
          <div className={classes.headingWrapper}>
            <h1 className={classes.titleHead}>Dynamic Content</h1>
          </div>
          <Menu containerClassname={classes.mb0} pages={pages} width="100%" />
        </div>
        {/* main action div */}
        <div className={classes.actionContainer}>
          <div id="listContainer" className={selectedHazard ? classes.listContainerActive : classes.listContainer}>
            {selectedHazard && <div className={classes.drop} />}
            <Input
              onChange={(val, type) => {
                setSelectedSite(val.value);
              }}
              value={
                selectedSite &&
                siteOptions?.filter((item) => item.value === selectedSite)[0]
              }
              tabSelectsValue={
                selectedSite &&
                siteOptions?.filter((item) => item.value === selectedSite)[0]
              }
              options={siteOptions}
              type={"select"}
              label={"Filter by site"}
              placeholder={"Select a site from the list"}
              component={Input}
              name="site_list"
              order="column"
              hasTooltip={true}
              tooltipText={"All sites are displayed by default"}
            />
            <DragDropContext onDragEnd={(results) => onDragEnd(results)}>
              <Droppable
                droppableId={"droppable"}
                isDropDisabled={selectedHazard ? true : false}
              >
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    id="droppableList"
                  >
                    {hazardList?.map((item, index) => (
                      <Draggable
                        key={item?.dragId}
                        draggableId={item?.dragId?.toString()}
                        index={index}
                        isDragDisabled={selectedHazard ? true : false}
                      >
                        {(provided, snapshot) => (
                          <div
                            className={cn(
                              selectedHazard.dragId === item.dragId &&
                                classes.z100
                            )}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <CardBox
                              selectedHazard={selectedHazard}
                              setEditBoxOpen={setEditBoxOpen}
                              setSelectedHazard={setSelectedHazard}
                              item={item}
                              totalSites={totalSites}
                              setSelectActionIndex={setSelectActionIndex}
                              index={index}
                              selectedActionIndex={selectedActionIndex}
                              hazardList={hazardList}
                              setHazardList={setHazardList}
                              setGlobalChangeCount={setGlobalChangeCount}
                              deleteHazard={deleteHazard}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          {/* edit section */}
          <div
            className={cn(
              classes.editContainer,
              isAnimationActive && classes.slideIn,
              isEditBoxOpen === false && classes.slideOut
            )}
          >
            <EditArea
              setSelectedHazard={setSelectedHazard}
              selectedHazard={selectedHazard}
              siteOptions={siteOptions}
              totalSites={totalSites}
              setGlobalChangeCount={setGlobalChangeCount}
              isEditBoxOpen={isEditBoxOpen}
              setEditBoxOpen={setEditBoxOpen}
            />
          </div>
          {/* existing hazard section */}

          <ExistingHazardsSelector
            isExistingAnimationActive={isExistingAnimationActive}
            isAnimationActive={isAnimationActive}
            isEditBoxOpen={isEditBoxOpen}
            onSelect={(item) => {
              addNewItem(item);
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            }}
          />
        </div>

        {/* footer section */}
        <Footer
          loading={loading}
          selectedHazard={selectedHazard}
          globalChangeCount={globalChangeCount}
          onDelete={() => {
            if (selectedHazard?.id) {
              deleteHazard({
                id: selectedHazard.id,
              });
              setSelectedHazard(false);
              setEditBoxOpen(false);
            } else {
              const newList = hazardList.filter(
                (item) => item.dragId !== selectedHazard.dragId
              );

              setHazardList(newList);
              setSelectedHazard(false);
              setEditBoxOpen(false);
            }
          }}
          onSaveChanges={() => {
            saveChanges();
          }}
          onNewHazard={() => {
            addNewItem({
              dragId: `${randomIntFromInterval(0, 1999999)}newHz${
                hazardList?.length
              }${randomIntFromInterval(0, 1999999)}`,
              name: "New Content",
              site_list: [],
            });
          }}
          onCancelSelectedHazard={() => {
            setEditBoxOpen(false);
            setSelectedHazard(false);
          }}
          onCancelChanges={() => {
            setEditBoxOpen(false);

            const initHazards = hazards?.results?.map((item) => {
              return {
                dragId: item.id,
                ...item,
                site_list: !isEmpty(item.site_list)
                  ? item.site_list.map((item) => {
                      return {
                        value: item.id,
                        label: item.name,
                      };
                    })
                  : item.site_list,
                is_active: item.is_active === true ? 1 : 0,
                is_for_all_sites: item.is_for_all_sites === true ? 1 : 0,
              };
            });
            setHazardList(initHazards);
            setGlobalChangeCount(0);
            setSelectedHazard(false);
          }}
        />
      </div>
      <RouteLeavingGuard
        // When should shouldBlockNavigation be invoked,
        // simply passing a boolean
        // (same as "when" prop of Prompt of React-Router)
        confirmModal={true}
        when={globalChangeCount > 0}
        // Navigate function
        navigate={(path) => history.push(path)}
        // Use as "message" prop of Prompt of React-Router
        shouldBlockNavigation={(location) => {
          return true;
        }}
      />
    </>
  );
};

const mapStateToProps = createStructuredSelector({
  hazards: makeSelectHazards(),
  loading: makeSelectLoading(),
  sites: makeSelectSites(),
});
const mapDispatchToProps = (dispatch) => {
  return {
    getHazards: (val) => dispatch(getHazardsReq(val)),
    getSites: (val) => dispatch(getSitesRequest(val)),
    postHazards: (val) => dispatch(postHazardsReq(val)),
    resetHazardImage: (val) => dispatch(resetHazardImageReq(val)),
    deleteHazard: (val) => dispatch(deleteHazardReq(val)),
    resetHazards: (val) => dispatch(resetHazardsReq(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Hazards);
