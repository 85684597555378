import React from "react";
import FlipCard from "../flipCard";
import TextImageArray from "../textImageArray";
import TextWithBackgroundImage from "../textWithBackgroundImage";
import Carousel from "../carousel";
import HotspotsCard from "../hotspotsCard";
import Assesment from "../assessment";
import CardSort from "../cardSort";
import VRCards from "../vrCards";

const components = (type, data, setFooterVisibilityReq, isPreview=false) => {
  switch (type) {
    case "title":
      return <h1 style={{ padding: "15px 15px" }}>{data.content}</h1>;
    case "text":
      return <p style={{ padding: "0 15px" }}>{data.content}</p>;
    case "image_text_regular":
      return (
        <TextImageArray
          header={data.header}
          headerId={data.header_id}
          title={data.title}
          titleId={data.title_id}
          content={data.content}
          contentId={data.content_id}
          image={data.image}
        />
      );
    case "flipcard":
      return (
        <FlipCard
          setFooterVisibilityReq={setFooterVisibilityReq}
          interactive={data.interactive}
          title={data.title}
          title_id={data.title_id}
          content={data.content}
          content_id={data.content_id}
          items_per_row={data.items_per_row}
          show_title_bar={data.show_title_bar}
          cards={data.cards}
          isPreview={isPreview}
        />
      );
    case "image_text_full":
      return (
        <TextWithBackgroundImage
          interactive={data?.interactive}
          title={data.title}
          title_id={data.title_id}
          description={data.content}
          description_id={data.content_id}
          src={data.image}
        />
      );
    case "assesment":
      return (
        <Assesment
          interactive={data.interactive}
          assessmentId={data.data.assesmentId}
        />
      );
    case "hotspots":
      console.log('isPreview',isPreview);
      return (
        <HotspotsCard
          interactive={data.interactive}
          config={{
            intro: {
              title: data.title,
              title_id: data.title_id,
              content: data.content,
              content_id: data.content_id,
              button_label: data.button_label,
              button_label_id: data.button_label_id
            },
            end: {
              title: data.conclusion_title,
              title_id: data.conclusion_title_id,
              content: data.conclusion_content,
              content_id: data.conclusion_content_id
            },

            hotspots: data.hotspots,
            background: data.background,
          }}
          setFooterVisibilityReq={setFooterVisibilityReq}
          isPreview={isPreview}
        />
      );
    case "carousel":
      return (
        <Carousel
          setFooterVisibilityReq={setFooterVisibilityReq}
          interactive={data.interactive}
          config={data.content}
          isPreview={isPreview}
        />
      );
    case "card_sort":
      return (
        <CardSort
          setFooterVisibilityReq={setFooterVisibilityReq}
          interactive={data.interactive}
          question={data}
          isPreview={isPreview}
        />
      );
    case "vr":
        return (
          <VRCards
            setFooterVisibilityReq={setFooterVisibilityReq}
            interactive={data.interactive}
            vrcardsdata={data}
            sendVrData={function(){}}
          />
        );
    default:
      return <h1>Not Found</h1>;
  }
};

export const getComponent = (type, data, setFooterVisibilityReq, isPreview=false,selectedLanguage=null) => {
  return components(type, data, setFooterVisibilityReq,isPreview,selectedLanguage);
};
