import React, { useEffect, useState } from "react";
import classes from "./vrCards.module.scss";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { format, parseISO } from "date-fns";
import { isEmpty, isNil } from "lodash";
import { FormattedMessage } from "react-intl";

import Button from "components/UI/button";
import Footer from "components/UI/footer";
import Loading from "components/UI/loading";

import TickIcon from "../../../pages/LookAround/tick.svg";

import { getMeRequest, setMeRequest } from "../../../store/auth/actions";
import {
    makeSelectMe,
    makeSelectLoading as makeSelectMeLoading,
  } from "../../../store/auth/selector";

import { makeSelectSelectedLanguage } from "store/language/selector";


const VRCards = ({
    interactive,
    vrcardsdata,
    selectedLanguage,
    loading,
    getMe,
    setMe,
    me,
    sendVrData
  }) => {

    const [vrStatus, setVrStatus] = useState();
    const [vrData, setVrData] = useState([]);


    useEffect(() => {

        getMe();
    
      }, []);
    
    useEffect(() => {

        if (!isNil(me)) 
        {           
          setVrStatus(me?.vrstatus);

          if (isNil(me?.vrstatus) || JSON.parse(me?.vrstatus).length == 0) {

            const statusData = vrcardsdata?.vr?.map((item, index) => {
              return {
                id: index,
                completed: false,
              };
            });
            setVrData(statusData);
            sendVrData(statusData); //send to the page
          } else {
            setVrData(JSON.parse(me?.vrstatus));
            sendVrData(JSON.parse(me?.vrstatus));
          }
        }
        
      }, [me,vrcardsdata]);



    if (loading && isEmpty(vrData)) {
        return (
          <div className={classes.loadingWrapper}>
            {" "}
            <Loading />
          </div>
        );
    }


    return (
        <>
          <div className={classes.Container}>

            <div className={classes.vrWrapper}>
              {vrcardsdata?.vr.map((item, index) => (
                <>
                  <div className={classes.VRContainer}>
                    {vrData[index]?.completed && (
                      <div className={classes.completedWrapper}>
                        <img src={TickIcon} />
                      </div>
                    )}
                    <img
                      alt="VR Experience 1"
                      className={classes.Image}
                      src={item.image}
                    />
                  </div>
                  <div className={classes.ButtonContainer}>
                    <Button
                      borderRadius={"6px"}
                      height={"44px"}
                      text={"Take a look"}
                      theme="blue"
                      formatID={"take-a-look-button.text"}
                      onClick={() => {
                        const newVRData = vrData.map((a, i) => {
                          return {
                            ...a,
                            completed: i === index ? true : a.completed,
                          };
                        });
                        setMe({
                          vrstatus: JSON.stringify(newVRData),
                          language: selectedLanguage?.code,
                          callBack: () => {
                            return (window.location = item.url);
                          },
                        });
                      }}
                      disabled={false}
                    />
                  </div>
                </>
              ))}
            </div>
          </div>
        </>
    );



  };


  const mapStateToProps = createStructuredSelector({
    me: makeSelectMe(),
    loading: makeSelectMeLoading(),
    selectedLanguage: makeSelectSelectedLanguage(),
  });
  
  const mapDispatchToProps = (dispatch) => {
    return {
      getMe: (val) => dispatch(getMeRequest(val)),
      setMe: (val) => dispatch(setMeRequest(val)),
    };
  };
  
  export default connect(mapStateToProps, mapDispatchToProps)(VRCards);