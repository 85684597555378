import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import PersonaSelection from "../pages/PersonaSelection";
import Auth from "../pages/Auth";
import Register from "../pages/Register";
// import CovidQuestions from "../pages/CovidQuestions";
import PostLogin from "../pages/PostLogin";

//Dummy Logout
import Logout from "../pages/DummyLogout";

// Pages
import Introductory from "../pages/Introductory";
import Welcome from "../pages/Welcome";
import LookAround from "../pages/LookAround";
import Induction from "../pages/Induction";
import CardSystem from "../pages/CardSystem";
import SiteRules from "../pages/SiteRules";
import AllChaptersComplete from "../pages/AllChaptersComplete";
import Complete from "../pages/Complete";
import Test from "../pages/Test";
import SiteSelector from "../pages/SiteSelector";
import Assesment from "../pages/Assessment";
import LanguageSelection from "pages/LanguageSelection";
import Camera from "../pages/Camera";
import TinCan from "../pages/TinCan";
import TinCanLogin from "../pages/TinCan/Login";


//Supervisor Related Page
import SupervisorAuth from "../pages/Supervisor/Auth";
import SupervisorStaffVerify from "../pages/Supervisor/StaffVerify";
import SupevisorPeople from "../pages/Supervisor/People";
import ContractorDetail from "../pages/Supervisor/ContractorStats";
import SupervisorDashboard from "../pages/Supervisor/Dashboard";
import SupervisorCompany from "../pages/Supervisor/Company";
import SupervisorCreateForm from "../pages/Supervisor/Company/form";
import SupervisorInductions from "../pages/Supervisor/Inductions";
import SupervisorInductionEditor from "../pages/Supervisor/Inductions/Editor";
import SupervisorSettings from "../pages/Supervisor/Settings";
import SupervisorSites from "../pages/Supervisor/Site";
import SupervisorSiteDetailForm from "../pages/Supervisor/Site/Detail";
import SupervisorSiteCreateForm from "../pages/Supervisor/Site/Create";
import SupervisorSiteQrCode from "../pages/Supervisor/Site/QrCode";
import SupervisorSiteContractors from "../pages/Supervisor/Site/Contractors";
import SupervisorSiteContractorsDetail from "../pages/Supervisor/Site/Contractors/detail";
import SupervisorReporting from "../pages/Supervisor/Reporting";
import SupervisorNotifications from "pages/Supervisor/Notifications";
import SupervisorNotificationsCreate from "pages/Supervisor/Notifications/createNotification";
import SupervisorHazards from "pages/Supervisor/Hazards";
import SupervisorHazardsSettings from "pages/Supervisor/Hazards/settings";

import CheckOut from "../pages/CheckOut";

const supervisorRoutes = [
  {
    path: "/supervisor-dashboard",
    component: SupervisorDashboard,
    type: "supervisor",
  },

  {
    path: "/supervisor-reporting",
    component: SupervisorReporting,
    type: "supervisor",
  },
  {
    path: "/supervisor-reporting-inductions",
    component: SupervisorReporting,
    type: "supervisor",
  },
  {
    path: "/supervisor-reporting-cards",
    component: SupervisorReporting,
    type: "supervisor",
  },
  {
    path: "/supervisor-reporting-attendance",
    component: SupervisorReporting,
    type: "supervisor",
  },
  {
    path: "/supervisor-reporting-attendance-detail",
    component: SupervisorReporting,
    type: "supervisor",
  },

  {
    path: "/supervisor-people",
    component: SupevisorPeople,
    type: "supervisor",
  },
  {
    path: "/supervisor-people-contractors",
    component: SupevisorPeople,
    type: "supervisor",
  },
  {
    path: "/supervisor-people-visitors",
    component: SupevisorPeople,
    type: "supervisor",
  },
  {
    path: "/supervisor-people-staff",
    component: SupevisorPeople,
    type: "supervisor",
  },
  {
    path: "/supervisor-company-create",
    component: SupervisorCreateForm,
    type: "supervisor",
  },
  {
    path: "/supervisor-company-edit",
    component: SupervisorCreateForm,
    type: "supervisor",
  },
  {
    path: "/supervisor-company",
    component: SupervisorCompany,
    type: "supervisor",
  },
  {
    path: "/supervisor-sites-create",
    component: SupervisorSiteCreateForm,
    type: "supervisor",
  },
  {
    path: "/supervisor-sites-detail",
    component: SupervisorSiteDetailForm,
    type: "supervisor",
  },
  {
    path: "/supervisor-sites-qr",
    component: SupervisorSiteQrCode,
    type: "supervisor",
  },
  {
    path: "/supervisor-sites-contractors-detail",
    component: SupervisorSiteContractorsDetail,
    type: "supervisor",
  },
  {
    path: "/supervisor-sites-contractors",
    component: SupervisorSiteContractors,
    type: "supervisor",
  },

  {
    path: "/supervisor-sites",
    component: SupervisorSites,
    type: "supervisor",
  },
  {
    path: "/supervisor-inductions",
    component: SupervisorInductions,
    type: "supervisor",
  },
  {
    path: "/supervisor-induction-editor",
    component: SupervisorInductionEditor,
    type: "supervisor",
  },   
  {
    path: "/supervisor-settings",
    component: SupervisorSettings,
    type: "supervisor",
  },
  {
    path: "/supervisor-settings-translations",
    component: SupervisorSettings,
    type: "supervisor",
  },

  {
    path: "/supervisor-settings-roles-create",
    component: SupervisorSettings,
    type: "supervisor",
  },

  {
    path: "/supervisor-settings-roles-edit",
    component: SupervisorSettings,
    type: "supervisor",
  },

  {
    path: "/supervisor-settings-roles",
    component: SupervisorSettings,
    type: "supervisor",
  },

  {
    path: "/supervisor-settings-users-create",
    component: SupervisorSettings,
    type: "supervisor",
  },

  {
    path: "/supervisor-settings-users-edit",
    component: SupervisorSettings,
    type: "supervisor",
  },

  {
    path: "/supervisor-settings-users-import-csv",
    component: SupervisorSettings,
    type: "supervisor",
  },

  {
    path: "/supervisor-settings-users",
    component: SupervisorSettings,
    type: "supervisor",
  },
  {
    path: "/supervisor-settings-branch",
    component: SupervisorSettings,
    type: "supervisor",
  },
  {
    path: "/supervisor-settings-branch-create",
    component: SupervisorSettings,
    type: "supervisor",
  },
  {
    path: "/supervisor-settings-branch-edit",
    component: SupervisorSettings,
    type: "supervisor",
  },
  {
    path: "/supervisor-contractor-detail",
    component: ContractorDetail,
    type: "supervisor",
  },
  {
    path: "/supervisor-notifications",
    component: SupervisorNotifications,
    type: "supervisor",
  },
  {
    path: "/supervisor-notifications-create",
    component: SupervisorNotificationsCreate,
    type: "supervisor",
  },
  {
    path: "/supervisor-content-update",
    component: SupervisorHazards,
    type: "supervisor",
  },
  {
    path: "/supervisor-content-settings",
    component: SupervisorHazardsSettings,
    type: "supervisor",
  },
  { path: "/", component: () => <Redirect to="/supervisor-dashboard" /> },
];

const inductionRoutes = [
  { path: "/site-selector", component: SiteSelector },
  { path: "/introduction", component: Introductory, type: "induction" },
  { path: "/welcome", component: Welcome, type: "induction" },
  { path: "/take-a-look", component: LookAround, type: "induction" },
  { path: "/rules", component: Induction, type: "induction", layout: "normal" },
  { path: "/card-system", component: CardSystem, type: "induction" },
  { path: "/site-rules", component: SiteRules, type: "induction" },
  {
    path: "/all-chapters-complete",
    component: AllChaptersComplete,
    type: "induction",
  },
  {
    path: "/assessment",
    component: Assesment,
    type: "induction",
    layout: "normal",
  },

  { path: "/complete", component: Complete, type: "induction" },
  { path: "/test", component: Test, type: "induction" },
  { path: "/camera", component: Camera }, //Document Capture

  // this route should be at the end of all other routes
  { path: "/", component: () => <Redirect to="/site-selector" /> },
];

const authRoutes = [
  {
    path: "/supervisor-login",
    component: SupervisorAuth,
    type: "supervisor",
  },
  { path: "/logout", component: Logout },
  {
    path: "/staff-verify",
    component: SupervisorStaffVerify,
    type: "supervisor",
  },
  { path: "/check-out", component: CheckOut },
  { path: "/persona-selection", component: PersonaSelection },
  { path: "/language-selection", component: LanguageSelection },

  { path: "/login", component: Auth },
  { path: "/register", component: Register }, //This is simplified dummy login
  { path: "/tincan", component: TinCan }, //This is Tincan launcher link
  { path: "/tincan-login", component: TinCanLogin }, //This is Tincan login link
  
  // { path: "/covid-check", component: CovidQuestions },
  // { path: "/post-login-success", component: PostLoginSuccess },
  { path: "/post-login", component: PostLogin, layout: "private" },
];

export { inductionRoutes, authRoutes, supervisorRoutes };
