// @flow
import { put, call, takeLatest } from "redux-saga/effects";
import {
  DUPLICATE_INDUCTION_SUCCESS,
  DUPLICATE_INDUCTION_ERROR,
  DUPLICATE_INDUCTION,
} from "../../constants";
import axios from "../../../../services/api";
import { push } from "connected-react-router";
import { success } from "../../../../main";
import { isEmpty, isNil } from "lodash";

function* duplicateInductionRequest(data) {
  try {
    const values = data;
    const params = new URLSearchParams();
    
    //console.log('all data',values.status);

    params.append("id", values.id);

    const response = yield call(axios.post, `/inductions/`, params);
    if (response.data.error) {
      yield put({
        type: DUPLICATE_INDUCTION_ERROR,
        data: response.error,
      });
    } else {
      console.log("response", response);
      yield put({
        type: DUPLICATE_INDUCTION_SUCCESS,
        data: response.data,
      });
    }
    yield call(success, `Induction Updated Succesfully`);
  } catch (error) {
    yield put({
      type: DUPLICATE_INDUCTION_ERROR,
      data: error,
    });
  }
}

export default function* duplicateInductionRequestSaga() {
  yield takeLatest(DUPLICATE_INDUCTION, duplicateInductionRequest);
}
